import { useEffect, useState } from "react";
import { UserCard } from "../../interfaces/userCard";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Alert,
} from "@mui/material";

import "../../pages/styles.scss";
import api from "../../services/api";
import { PlanDetailDto, PlanDetailDtoHandler } from "../../dtos/PlanDetail";
import CardLoading from "../CardLoading";
import { Flags } from "../../assets/imgs";

function cardMask(value: string) {
  let v;
  v = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = value.replace(/(\d{4})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = value.replace(/(\d{4})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = value.replace(/(\d{4})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = value.replace(/(\d{4})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  return v;
}

interface CardProps {
  user: UserCard;
  onChange: (user: UserCard) => void;
  onSave: (user: UserCard) => void;
  period?: string;
  value?: number;
  planId: string;
  onPortion: (data: string) => void;
  portion: string;
  loading: boolean;
  marketPlan: number;
  button: boolean;
  textValidateErro: String;
  textButton: boolean;
}

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();
const mesAtual = dataAtual.getMonth() + 1;

export default function Cartao(props: CardProps) {
  const [planDetail, setPlanDetail] = useState<PlanDetailDto>(
    PlanDetailDtoHandler.getDefault()
  );
  const [isPortion, setIsPortion] = useState(props.portion);
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
    field: "",
  });

  useEffect(() => {
    loadPlanDetail();
  }, []);

  if (props.loading) {
    return (
      <div className="content__campos_pagamento">
        <CardLoading />
      </div>
    );
  }

  async function loadPlanDetail() {
    await api
      .get(`/planos/${props.planId}/${props.period}/2`)
      .then((response) => {
        const newPlanDetails = PlanDetailDtoHandler.getByDatabase(
          response.data[0]
        );
        setPlanDetail(newPlanDetails);
        loadPortion();
      })
      .catch(() => console.log("Erro ao buscar o detalhes do plano"));
  }

  function loadPortion() {
    setIsPortion(planDetail.installment || "");
  }

  function handleChangeUserCard(key: string, value: any) {
    props.onChange({ ...props.user, [key]: value });
  }

  function handleValidate() {
    const cardYearAtual = Number("20" + props.user.cardYear);

    if (!props.user.cardNumber)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o número do cartão.",
        field: "cardNumber",
      });
    if (!props.user.cardName)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o nome do titular do cartão.",
        field: "cardName",
      });
    if (!props.user.cardMonth)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o mês de validade do cartão.",
        field: "cardMonth",
      });
    if (Number(props.user.cardMonth) > 12)
      return setStatus({
        type: "error",
        mensagem: "Preencha o mês corretamente.",
        field: "cardMonth",
      });
    if (!props.user.cardYear)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o ano de validade do cartão.",
        field: "cardYear",
      });
    if (cardYearAtual < anoAtual)
      return setStatus({
        type: "error",
        mensagem: "Validade do cartão incorreta.",
        field: "cardYear",
      });
    if (cardYearAtual === anoAtual && Number(props.user.cardMonth) < mesAtual)
      return setStatus({
        type: "error",
        mensagem: "Validade do cartão incorreta.",
        field: "cardMonth",
      });
    if (!props.user.cardCod)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o codigo de segurança do cartão.",
        field: "cardCod",
      });
    if (!props.marketPlan)
      return setStatus({
        type: "error",
        mensagem: "Necessário selecionar o mercado.",
        field: "",
      });
    return true;
  }

  function handleSubmitCard(e: any) {
    e.preventDefault();

    if (!handleValidate()) return;

    props.onSave(props.user);
  }

  let portionArray = new Array(Number(props.portion));

  for (let i = 0; i < Number(props.portion); i++) {
    portionArray[i] = [i + 1];
  }

  return (
    <>
      {status.type === "error" ? (
        <p
          style={{ color: "#ff0000", marginLeft: "32px", marginBottom: "8px" }}
        >
          {status.mensagem}
        </p>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmitCard} className="p-4 space-y-6">
        <p className="text-xl">Informações de Pagamento</p>
        <div className="space-y-4">
          <TextField
            label="Número do cartão"
            size="small"
            type={"tel"}
            color={status.field === "cardNumber" ? "warning" : "success"}
            focused={status.field === "cardNumber" ? true : false}
            placeholder="Apenas números"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handleChangeUserCard("cardNumber", e.target.value || "");
              const { value } = e.target;
              e.target.value = cardMask(value);
            }}
            inputProps={{ maxLength: 19 }}
            style={{width: "100%"}}
          />
          <TextField
            label="Nome impresso no cartão"
            size="small"
            placeholder="Seu nome"
            type={"text"}
            color={status.field === "cardName" ? "warning" : "success"}
            focused={status.field === "cardName" ? true : false}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handleChangeUserCard("cardName", e.target.value || "");
            }}
            style={{width: "100%"}}
          />
        </div>
        <div className="flex gap-2 items-end">
          <div className="flex gap-2">
            <TextField
              label="Mês"
              type="tel"
              size="small"
              placeholder="DD"
              color={status.field === "cardMonth" ? "warning" : "success"}
              focused={status.field === "cardMonth" ? true : false}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const month = e.target.value
                if(month.length >= 2){
                  document.getElementById('year-input')?.focus()
                }
                handleChangeUserCard("cardMonth", e.target.value || "");
              }}
              inputProps={{ maxLength: 2 }}
            />
            <TextField
              id="year-input"
              label="Ano"
              type="tel"
              size="small"
              placeholder="MM"
              color={status.field === "cardYear" ? "warning" : "success"}
              focused={status.field === "cardYear" ? true : false}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const month = e.target.value
                if(month.length >= 2){
                  document.getElementById('cvv-input')?.focus()
                }
                handleChangeUserCard("cardYear", e.target.value || "");
              }}
              inputProps={{ maxLength: 2 }}
            />
          </div>
          <TextField
            id="cvv-input" 
            label="CVV"
            type={"tel"}
            size="small"
            color={status.field === "cardCod" ? "warning" : "success"}
            focused={status.field === "cardCod" ? true : false}
            placeholder="000"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handleChangeUserCard("cardCod", e.target.value || "");
            }}
            inputProps={{ maxLength: 3 }}
            style={{ width: "95px" }}
          />
        </div>
        <div
          className={
            props.period === "Monthly"
              ? "conteiner__opcaoOff"
              : "conteiner__opcao"
          }
        >
          <p
            className="text_opcao"
            style={{ marginLeft: "18px", color: "#969696" }}
          >
            Quantidade de parcelas :
          </p>
          <FormControl
            size="small"
            color="success"
            className="select_mes_boleto"
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "#969696" }}
            >
              Parcelas
            </InputLabel>
            <Select
              key={props.portion}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Periodo"
              className="select_boleto"
              required
              defaultValue={props.portion}
              onChange={(e) => {
                handleChangeUserCard("portion", e.target.value || "");
              }}
              style={{ color: "#969696" }}
            >
              {portionArray.map((u: any) => (
                <MenuItem
                  value={String(u)}
                  key={u}
                  onClick={() => props.onPortion(String(u))}
                  style={{ color: "#969696" }}
                >
                  {u}x
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <img src={Flags} className="bandeiras" alt="" />
        {props.textValidateErro !== "" ? (
          <Alert variant="outlined" severity="error">
            {props.textValidateErro}
          </Alert>
        ) : (
          ""
        )}
        <Button
          variant="contained"
          fullWidth
          color={"success"}
          type="submit"
          size="large"
          disabled={props.button}
          disableElevation
          sx={{
            mt: 1,
            background: "#7EC057",
            ":hover": { bgcolor: "#6faa4e" },
          }}
        >
          {props.textButton === true ? "Tentar novamente" : "Finalizar"}
        </Button>
      </form>
    </>
  );
}
