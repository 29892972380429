import CryptoJS from "crypto-js";

export function decryptDiscount(encryptedDiscount: string | null) {
    if (!encryptedDiscount) return 0;
    const bytes = CryptoJS.AES.decrypt(encryptedDiscount, String(process.env.REACT_APP_SECRET_KEY));
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const discount = Number(decryptedData.discount);
  
    localStorage.removeItem("d"); // clears promotion discount from localStorage
  
    return discount;
  }