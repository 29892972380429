import { Routes, Route } from "react-router-dom";
// Pages Imports
import {
  CookiesPolicy,
  Forgot,
  Form,
  Home,
  Login,
  NotFound,
  OneNews,
  Payment,
  Plans,
  PrivacyPolicy,
  RenewPayment,
  RenewPaymentMp,
  RuralBusinessCommitment,
  UseLicenseAgreement,
} from "./pages";
import {
  Videos,
  Graphics,
  Movies,
  News,
  Quotes,
  MyAccount,
  Indicators,
} from "./pages/Dashboard";

import LoginToken from "./pages/LoginToken";
import PagamentoMp from "./pages/MercadoPago/PaymentMp/pagamentoMp";

// Components Imports
import InnerContent from "./components/InnerContent";
import ProtectedRoute from "./components/ProtectedRoute";
import PaymentPix from "./components/PaymentPix";
import BoletoPayment from "./components/BoletoPayment";
import { AfterPayment, PaymentError } from "./components";

export default function RoutesComponents() {
  return (
    <Routes>
      <Route path="/:ad?" element={<Home />} />
      {/* rotas publicas */}
      <Route
        path="/login"
        element={
          <Login
            setToken={function (...args: any[]) {
              throw new Error("Function not implemented.");
            }}
          />
        }
      />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/plan/:ad?" element={<Plans variant={process.env.REACT_APP_PLANS_VARIANT} />} />
      {/* <Route
        path="/plan/blackfriday/:ad?"
        element={<Plans variant={process.env.REACT_APP_PLANS_VARIANT} />}
      /> */}
      <Route path="/*" element={<NotFound />} />
      <Route path="/email/:token" element={<LoginToken />} />
      <Route path="/dados/:planId/:mercado/:ad?" element={<Form />} />
      {/* ROTAS CIELO não sabemos se será utilizado */}
      <Route path="/pagamento/cielo/:id/:mercado/:ad/:clientId?/:userToken?" element={<Payment />} />
      {/* Troca de Planos */}
      <Route path="/planos/:clientId/:ad?" element={<Plans variant={process.env.REACT_APP_PLANS_VARIANT} />} />
      <Route
        path="/pix/:value/:planId/:clientId/:paymentMethod/:marketSelect"
        element={<PaymentPix />}
      />
      <Route path="/boleto/:contId/:titleId" element={<BoletoPayment />} />
      <Route path="/pagamento/afterPayment" element={<AfterPayment />} />
      <Route path="/pagamento/error" element={<PaymentError />} />
      {/* ROTAS PARA MERCADO PAGO */}
      <Route
        path="/pagamento/mp/:planId/:mercado/:clientId"
        element={<PagamentoMp />}
      />
      {/* Link de renovação */}
      <Route
        path="/renewLink/pagamento/mp/:planId/:mercado/:ad/:clientId?/:userToken?"
        element={<RenewPaymentMp />}
      />

      {/* Termos e Contratos */}
      <Route path="/cookiesPolicy" element={<CookiesPolicy />} />
      <Route path="/useLicenseAgreement" element={<UseLicenseAgreement />} />
      <Route
        path="/ruralBusinessCommitment"
        element={<RuralBusinessCommitment />}
      />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

      {/* Rotas protegidas */}
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<InnerContent />}>
          <Route path="/dashboard/video" element={<Videos />} />
          <Route path="/dashboard/prices" element={<Quotes />} />
          <Route path="/dashboard/graphic" element={<Graphics />} />
          <Route path="/dashboard/movies" element={<Movies />} />
          <Route path="/dashboard/news" element={<News />} />
          <Route path="/dashboard/indicators" element={<Indicators />} />
          <Route path="/dashboard/news/:news" element={<OneNews />} />
          <Route path="/dashboard/myAccount" element={<MyAccount />} />
          <Route
            path="/dashboard/renewPlan/:clientId/:contract/:title/:ad?"
            element={<Plans variant={process.env.REACT_APP_PLANS_VARIANT} />}
          />
          <Route
            path="/dashboard/renewPlan/pagamento/error"
            element={<PaymentError />}
          />
          {/* ROTA CIELO */}
          <Route
            path="/dashboard/renewPlan/pagamento/:planId/:clientId/:contract/:title"
            element={<RenewPayment />}
          />
          {/* ROTA MERCADO PAGO */}
          <Route
            path="/dashboard/renewPlan/pagamento/mp/:planId/:mercado/:ad?/:clientId"
            element={<RenewPaymentMp />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
