import CookieConsent from 'react-cookie-consent';
import Routes from './routes';
import ScrollButton from './components/ScrollButton';
import { BACKGROUND_BLACK, TEXT_WHITE } from './constants/colors';
import { FooterLayout, HeaderLayout } from './layouts';
import { Box, Link } from '@mui/material';
import './App.css';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChevronRight, ShoppingCart, WarningRounded } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker/locale/pt_PT';
import moment from 'moment';
import { getUserToken, loadAuth } from './utils/apiFunctions/auth';

export const notificacaoCompra = () => {
  const options = [
    "TOP",
    "TOP +",
    "BÁSICO"
  ]
  const num = Math.floor(Math.random() * (3 - 0) + 0)
  const fakerName = faker.person.fullName()
  const notify = () => toast(`${fakerName} Assinou o Plano ${options[num]}`);
  notify();
};

export default function App() {
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const [loginValidation, setLoginValidation] = useState<any>()
  const token = getUserToken()

  async function loadValidation () {
    const info = await loadAuth()
    setLoginValidation(info)
  }

  const minIntervalo = 10000; // 3 segundos em milissegundos
  const maxIntervalo = 20000; // 20 minutos em milissegundos

  useEffect(() => {
    loadValidation()
  }, [])

  useEffect(() => {
    // console.log(pathname)
    if (pathname === "/plan" || pathname === "/plan/blackfriday" || (path.find((el) => el === "renewPlan") && !path.find((el) => el === "pagamento"))) {
      let intervalId: any;

      const executarNotificacao = () => {
        const intervaloAleatorio =
          Math.random() * (maxIntervalo - minIntervalo) + minIntervalo;
        // console.log(intervaloAleatorio);

        intervalId = setInterval(() => {
          notificacaoCompra();
          clearInterval(intervalId); // Pare a notificação após a primeira execução
          executarNotificacao(); // Agende a próxima notificação
        }, intervaloAleatorio);
      };

      executarNotificacao();

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [pathname]);

  // expiring subscription message
  useEffect(() => {
    const lsExpiringMessage = localStorage.getItem("expiring");
    const expiringDate = moment(loginValidation?.cont_data_vencimento).diff(
      moment(),
      "days",
    );
    const isPortalSubscription = Number(loginValidation?.plan_id) >= 33; // filtering per subscription

    if (expiringDate <= 10) {
      if (!lsExpiringMessage && isPortalSubscription && token) {
        console.log("show toast")
        toast.warn(<div className='text-zinc-900'>
          <h3 className='text-base font-semibold'>Sua assinatura está prestes a expirar!</h3>
          <h4>
            Em {expiringDate} dias o período da sua assinatura se encerrará.
          </h4>
          <button
            onClick={() => {
              localStorage.setItem("expiring", String(true));
              toast.dismiss();
              window.open(`https://web.ruralbusiness.com.br/redirecionar?token=${encodeURIComponent(token)}&path=${encodeURIComponent('/central-do-assinante')}`)
            }}
            className='bg-blue-800 text-white p-2 rounded-md mt-1'
          >
            Renovar Agora
            <ChevronRight className="ml-1 size-4" />
          </button>
          </div>, {icon: <WarningRounded color="warning"/>, theme:"light", autoClose: 15000})
      }
    } else {
      localStorage.removeItem("expiring");
    }
  }, [loginValidation?.cont_data_vencimento, loginValidation?.plan_id, token]);

  return (
    <>
      <Box minHeight={'100vh'} bgcolor={path.find(value => value === 'blackfriday') ? '#0a0a0a' : ''}>
        <HeaderLayout />
        <ScrollButton />
        <Routes />
        <CookieConsent
          location="bottom"
          buttonText="Continuar"
          buttonStyle={{
            color: TEXT_WHITE,
            fontSize: '16px',
            background: BACKGROUND_BLACK,
            fontWeight: 'bold',
          }}
          expires={365}
        >
          Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa Política de Privacidade e, ao
          continuar navegando, você concorda com estas condições.{' '}
          <Link underline="always" href="/privacyPolicy" color={TEXT_WHITE}>
            {' '}
            Política de Privacidade
          </Link>{' '}
          <Link underline="always" href="/cookiesPolicy" color={TEXT_WHITE}>
            {' '}
            Política de Cookies
          </Link>
        </CookieConsent>
      </Box>
      <FooterLayout />
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        icon={<ShoppingCart />}
        limit={1}
        theme="light"
        pauseOnHover={false}
        closeOnClick
        closeButton
        hideProgressBar
        newestOnTop
      />
    </>
  );
}
