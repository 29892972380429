import api from '../../../services/api';
import getHeaders from './getHeaders';

//buscando auth do user pelo token.
export default async function loadAuth(handleAuth) {
  const result = await api
    .get('/clientes/logado', getHeaders())
    .then(response => {
      const { data } = response;

      if (data.error) {
        handleAuth?.(response.data.error);
      }
      return response.data;
      // console.log("Usuário autenticado");
    })
    .catch(() => console.log('Erro ao autenticar o usuário'));
  return result;
}
